<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <!-- <fb-button slot="right" icon="menu" size="small" type="primary">
      </fb-button> -->
    </fb-header>
    <div class="fb-summary">
      <div class="basic-info">
        <p class="basic-info-label"
           @click="showBasicDetail">
          <label class="label-item-entrance">基本信息</label>
          <i class="iconfont icon-child-off"></i>
        </p>
        <p class="basic-info-item">
          <label class="label-item">案由</label>
          <label class="value-item">{{ basicInfo.caseReasonName }}</label>
        </p>
        <p class="basic-info-item">
          <label class="label-item">受案时间</label>
          <label class="value-item">{{ basicInfo.acceptCaseTime }}</label>
        </p>
      </div>
      <div class="litigant-info"
           v-for="(ligitant, ligitantKey) in ligitants"
           :key="ligitantKey"
           v-show="ligitant.length > 0">
        <p class="litigant-info-item"
           v-for="(ligitantInfo, ligitantInfoIndex) in ligitant"
           :key="ligitantInfoIndex"
           @click="showLigitantDetail(ligitantMap[ligitantKey], ligitant, ligitantInfoIndex)">
          <label class="label-item">{{ ligitantMap[ligitantKey] }}</label>
          <label class="value-entrance">
            {{ ligitantInfo.name }}
            <i class="iconfont icon-child-off"></i>
          </label>
        </p>
      </div>
      <div class="organ-info">
        <p class="basic-info-label"
           @click="showOrganDetail">
          <label class="label-item-entrance">机关信息</label>
          <i class="iconfont icon-child-off"></i>
        </p>
        <p class="basic-info-item">
          <label class="label-item">法院名称</label>
          <label class="value-item">{{ organInfo.courtName }}</label>
        </p>
        <!-- <p class="basic-info-item">
          <label class="label-item">审判长</label>
          <label class="value-item">{{ organInfo.presidingJudge }}</label>
        </p> -->
      </div>
      <div class="piece-info"
           v-for="(textInfo, textKey) in textinfos"
           :key="textKey">
        <p class="piece-info-key">{{ textInfo.chinese }}</p>
        <div class="piece-textarea">
          <!-- <div class="content-editable" contenteditable="true">{{ textInfo.content }}</div> -->
          <textarea rows="3"
                    class="piece-info-value"
                    v-model="textInfo.content"
                    @blur="updataText"></textarea>
        </div>
      </div>
    </div>
  </fb-page>
</template>

<script>
import ligitantMap from './data/ligitant.json'
import util from './util'
import Qs from 'qs'
import { caseBase } from '~api-config'
export default {
  name: 'fb-info-summary',
  data() {
    return {
      title: '信息总览',
      ligitantMap: ligitantMap,
      ligitants: {},
      basicInfo: {},
      organInfo: {},
      textinfos: {
        claims: {
          chinese: '诉讼请求',
          content: ''
        },
        briefs: {
          chinese: '案情简要',
          content: ''
        }
      },
      isData: false,
      cudId: ''
    }
  },
  created() {
    const caseId = this.$route.params.caseId
    // caseId = 82 // todo 这里写死是为了便于测试，正式环境之前需要删除
    this.$axios
      .get(caseBase + '/lts/inf/infOverview', {
        params: {
          caseId: caseId
        }
      })
      .then(res => {
        const ligitants = {}
        // 按照当事人类型重新包装接口返回的数据
        res.data.data.litigantList.forEach(element => {
          if (!ligitants[element.procedureSubject]) {
            ligitants[element.procedureSubject] = [element]
          } else {
            ligitants[element.procedureSubject].push(element)
          }
        })
        this.ligitants = ligitants

        this.basicInfo = res.data.data.essentialInf
        this.basicInfo.acceptCaseTime = util.getFormatTime(
          this.basicInfo.acceptCaseTime
        )

        this.organInfo = res.data.data.officesInf
      })
    // caseId = 64964 // todo 这里写死是为了便于测试，上测试环境之前需要删除
    this.$axios
      .get(caseBase + '/new-design/caseUserDocument/queryByCaseId/' + caseId)
      .then(res => {
        if (res.data.data.caseUserDocument.length !== 0) {
          const textinfos = JSON.parse(
            res.data.data.caseUserDocument[0].documentData
          )[0]
          this.textinfos.claims.content = textinfos.suSongQingQiu_1.replace(
            /\^\^\^/g,
            '\n'
          )
          this.textinfos.briefs.content = textinfos.shiShiLiYou_1.replace(
            /\^\^\^/g,
            '\n'
          )
          this.isData = true
          this.cudId = res.data.data.caseUserDocument[0].cudId
        } else {
          this.textinfos.claims.content = '无数据'
          this.textinfos.briefs.content = '无数据'
        }
      })
  },
  methods: {
    showBasicDetail() {
      const caseId = this.$route.params.caseId
      // caseId = 82
      console.log('showBasicDetail')
      this.$router.push({
        name: 'casedetail-xxzl-detail',
        params: {
          basicInfo: this.basicInfo,
          agencyInfId: this.organInfo.agencyInfId,
          caseId: caseId
        }
      })
    },
    showLigitantDetail(ligitantType, ligitant, ligitantInfoIndex) {
      this.$router.push({
        name: 'casedetail-xxzl-ligitant',
        params: {
          ligitantType: ligitantType,
          ligitant: ligitant,
          ligitantInfoIndex: ligitantInfoIndex
        }
      })
    },
    showOrganDetail() {
      this.$router.push({
        name: 'casedetail-xxzl-organ',
        params: {
          caseId: this.$route.params.caseId,
          organInfo: this.organInfo
        }
      })
    },
    updataText() {
      const data = {
        caseId: this.$route.params.caseId,
        claimText: this.textinfos.claims.content,
        briefCaseText: this.textinfos.briefs.content
      }
      if (this.cudId) {
        data.id = this.cudId
        this.$axios
          .post(
            caseBase + '/new-design/caseUserDocument/updateDocumentData',
            Qs.stringify(data)
          )
          .then(response => {
            console.log(response)
          })
      } else {
        data.userId = this.$store.state.user.userInfo.id
        this.$axios
          .put(
            caseBase + '/new-design/caseUserDocument/create',
            Qs.stringify(data)
          )
          .then(response => {
            console.log(response)
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.fb-summary
  text-align center
  width 90%
  margin 10px auto
  &::after
    content ''
    position fixed
    height 100%
    width 100%
    z-index -1
    top 0
    left 0
    background #F0F2F5
  .basic-info, .litigant-info, .organ-info, .piece-info
    border-radius 5px
    background white
    font-size 15px
    box-shadow 0px 0px 0px 1px #DCF0FA
    margin-bottom 10px
    .basic-info-label, .basic-info-item, .litigant-info-item
      overflow hidden
      border-top 1px solid #80808024
      height 40px
      .label-item, .label-item-entrance
        padding-left 10px
        float left
        line-height 40px
      .label-item-entrance, .iconfont
        color #169BD5
      .iconfont, .value-item
        float right
        line-height 40px
        padding-right 10px
      .iconfont
        font-size 25px
  .litigant-info
    .litigant-info-item
      .label-item
        color #169BD5
      .value-entrance
        float right
        line-height 40px
        .iconfont
          font-size 25px
  .piece-info
    .piece-info-key
      text-align left
      margin-left 10px
      color #169BD5
      height 40px
      line-height 40px
    .piece-textarea
      border-top 1px solid #80808024
      position relative
      display block
      width 100%
      padding 0.1rem 0
      .content-editable
        position relative
        z-index -1
        opacity 0
        display block
        width 100%
        line-height 30px
        white-space pre-line
      .piece-info-value
        border none
        padding-left 10px
        padding-right 10px
        // position absolute
        // top 0
        // left 0
        width 100%
        height 100%
        box-sizing border-box
        // line-height 30px
        text-align justify
        resize none
        background-color transparent
</style>
